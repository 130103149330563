import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";

export const Program = ({ data }) => {
  const { items, primary } = data || {};
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <Title
        font={typography.type.code}
        tag="h3"
        weight={typography.weight.regular1}
        lineHeight={90}
        align="center"
        color={color.primary.blue}
        capital="uppercase"
      >
        <RichText
          render={primary?.title?.raw}
          htmlSerializer={htmlSerializer}
        />
      </Title>
      <WrapperContent>
        <WrapperGeneral>
          <List>
            {items &&
              items?.map((item, i) => {
                return (
                  <ListItem key={i}>
                    <HeaderItem>
                      <Icon src={item?.icon?.url} />
                      <Rank
                        size={typography.size.l3}
                        weight={typography.weight.regular1}
                        lineHeight={56}
                        font={typography.type.code}
                      >
                        {`0${i + 1}`}
                      </Rank>
                    </HeaderItem>
                    <BoxContent>
                      <TitleItem
                        size={typography.size.m1}
                        weight={typography.weight.bold}
                        lineHeight={28}
                        font={typography.type.primary}
                        color={color.netraul.black100}
                      >
                        {item?.reason?.text}
                      </TitleItem>
                      <Description
                        size={typography.size.m1}
                        weight={typography.weight.bold}
                        lineHeight={28}
                        font={typography.type.primary}
                        color={color.netraul.black100}
                      >
                        {item?.reason_description?.text}
                      </Description>
                    </BoxContent>
                  </ListItem>
                );
              })}
          </List>
        </WrapperGeneral>
      </WrapperContent>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  box-sizing: border-box;
`;
const WrapperContent = styled.div`
  width: 81%;
  margin: 0 auto 150px;
  @media (max-width: 1400px) {
    width: 91%;
    margin: 0 auto 80px;
  }
`;
const WrapperGeneral = styled.div``;
const Title = styled(Text)`
  letter-spacing: -0.015em;
  padding: 126px 0 60px;
  display: block;
  max-width: 91%;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding: 80px 0 40px;
    font-weight: ${typography.weight.regular1};
    font-size: ${typography.size.l4}px !important;
    line-height: 62px !important;
  }
`;
const Description = styled(Text)``;
const TitleItem = styled(Text)`
  display: block;
  padding-bottom: 16px;
`;
const BoxContent = styled.div`
  padding: 0 32px 60px;
`;
const List = styled.div`
  display: grid;
  grid-gap: 26px;
  grid-template-columns: auto auto auto auto;
  @media (max-width: 1250px) {
    grid-gap: 20px;
    grid-template-columns: auto auto;
  }
  @media (max-width: 650px) {
    grid-gap: 20px;
    grid-template-columns: auto;
  }
`;
const ListItem = styled.div`
  background-color: ${color.primary.blue05};
`;
const Icon = styled.img`
  padding-top: 20px;
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;
  @media (max-width: 650px) {
    padding-top: 0;
  }
`;
const Rank = styled(Text)`
  letter-spacing: -0.015em;
  color: #1729401a;
`;
const HeaderItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 20px 32px 32px;
`;
