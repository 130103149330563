import React from 'react'
import { ThemeProvider } from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Sidebar } from '../components/Sidebar';
import FooterContainer from '../containers/FooterContainer';
import { FundamentalContainer } from '../containers/FundamentalContainer';
import { Header } from '../containers/HeaderContainer';
import useApp, { withAppContext } from '../contexts/context'
import { useSiteMetadata } from '../hooks/useQuerydata';
import { useQueryFundamentals } from '../hooks/useQueryFundamental';

const Fundamental = () => {
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter, allPrismicHeader } = useSiteMetadata();
  const { allPrismicFundamentals } = useQueryFundamentals();
  const dataHeader = allPrismicHeader?.nodes[0]?.data;
  const theme = {
    bgColor: 'white',
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={<Header toggleLayout={handleToggleLayout} data={dataHeader} />}
        footer={<FooterContainer data={allPrismicFooter?.nodes[0]?.data} />}
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={<SEO data={allPrismicFundamentals?.nodes[0]?.data?.body6} />}
      >
        <FundamentalContainer
          data={allPrismicFundamentals?.nodes[0]?.data}
          seo={allPrismicFundamentals?.nodes[0]?.data?.body6}
        />
      </Layout>
    </ThemeProvider>
  );
}

export default withAppContext(Fundamental);
