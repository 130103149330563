import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { color, typography, spacing } from "../../shared/style";
import { Text } from "../../components/Text/Text";
import { Button } from "../../components/Button/Button";
import { navigate } from "gatsby";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import { redirectPage } from "../../hooks/redirect";
import linkedin_default from "../../images/linkedin-default.svg";
import linkedin_active from "../../images/linkedin-icon-active.svg";

export const Speaker = ({ data }) => {
  const { items, primary } = data || {};
  const [listShow, setListShow] = useState([]);

  useEffect(() => {
    let data = items;
    let array = [];
    if (typeof window !== "undefined" && window.innerWidth <= 1024) {
      array = data.slice(0, 4);
      setListShow(array);
    } else {
      array = data.slice(0, 5);
      setListShow(array);
    }
  }, [items]);
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <WrapperHeader>
        <Title
          font={typography.type.code}
          tag="h3"
          size={typography.size.code}
          weight={typography.weight.regular1}
          lineHeight={90}
          align="left"
          color={color.netraul.black100}
          capital="uppercase"
        >
          <RichText
            render={primary?.title?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Title>
        <BoxHeader>
          <ButtonExtendedHeader
            onClick={() =>
              // navigate("https://www.draperuniversity.com/speakers")
              redirectPage({
                url: primary?.button_link?.url,
                type: primary?.button_link?.type,
                link_type: primary?.button_link?.link_type
              })
            }
            type="primary"
            size={16}
            lineHeight={26}
          >
            {primary?.button_label?.text}
          </ButtonExtendedHeader>
        </BoxHeader>
      </WrapperHeader>
      <WrapperGeneral>
        {listShow?.length !== 0 &&
          listShow?.map((item, i) => {
            const ite = item?.team_member?.document?.data;
            const linkedin = ite?.contacts.find(
              (item) => item.contact_label === "linkedin"
            );
            return (
              <Content key={i}>
                <BoxImage>
                  <Image
                    src={item?.member?.document?.data?.avatar?.url}
                  />
                  {linkedin && (
                    <ButtonIcon
                    className="button"
                    onClick={() => 
                      redirectPage({
                        url: linkedin?.contact_link?.url,
                        type: linkedin?.contact_link?.type,
                        link_type: linkedin?.contact_link?.link_type,
                      })
                    }
                    // type="transparent"
                    icon_default={linkedin_default}
                    icon_active={linkedin_active}
                  ></ButtonIcon>
                  )}
                </BoxImage>
                <Name
                  className="name"
                  font={typography.type.code}
                  tag="h3"
                  color={color.netraul.black100}
                  capital="uppercase"
                  size={typography.size.l1}
                  weight={typography.weight.regular1}
                  lineHeight={36}
                  align="left"
                >
                  {item?.member?.document?.data?.name?.text}
                </Name>
                <Position
                  font={typography.type.primary}
                  color={color.netraul.black60}
                  size={typography.size.s2}
                  weight={typography.weight.regular}
                  lineHeight={22}
                  align="left"
                >
                  {item?.member?.document?.data?.position?.text}
                </Position>
              </Content>
            );
          })}
      </WrapperGeneral>
      <BoxFooter>
        <ButtonExtended
          onClick={() =>
            redirectPage("https://www.draperuniversity.com/speakers")
          }
          type="primary"
          size={16}
          lineHeight={26}
        >
          See all experts
        </ButtonExtended>
      </BoxFooter>
    </WrapperSection>
  );
};
const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  box-sizing: border-box;
  background: ${color.primary.white};
  padding-bottom: 185px;
  @media (max-width: 1024px) {
    padding-bottom: 80px;
  }
`;
const Title = styled(Text)`
  letter-spacing: -0.015em;
  display: block;
  span {
    font-size: ${typography.size.code}px !important;
    font-weight: ${typography.weight.regular1};
    line-height: 90px !important;
  }
  @media (max-width: 650px) {
    color: ${color.primary.blue};
  }
`;
const WrapperHeader = styled.div`
  width: 81%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 130px 0 50px;
  @media (max-width: 1400px) {
    padding: 80px 0 40px;
    width: 91%;
  }
`;
const BoxHeader = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 650px) {
    display: none;
  }
`;
const BoxFooter = styled.div`
  display: none;
  @media (max-width: 650px) {
    display: flex;
    justify-content: center;
  }
`;
const ButtonExtended = styled(Button)`
  border-radius: ${spacing.borderRadius.Larger}px;
  height: 65px;
  white-space: nowrap;
  margin-top: 32px;
  color: ${color.primary.white};
  padding: 20px 45px;
  @media (max-width: 650px) {
    margin-top: 40px;
  }
`;
const ButtonExtendedHeader = styled(ButtonExtended)`
  margin-top: 0px;
`;
const ButtonIcon = styled(Button)`
  position: absolute;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 0px;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  align-items: center;
  border: none;
  justify-content: center;
  background-image: url(${(props) => props.icon_default && props.icon_default});
  background-repeat: no-repeat;
  background-position: center center;
  @media (max-width: 650px) {
    visibility: visible;
    opacity: 1;
    height: 32px;
    width: 32px;
    right: 10px;
    bottom: 10px;
  }
  &:hover {
    background-image: url(${(props) => props.icon_active && props.icon_active});
    background-repeat: no-repeat;
    transform: scale(1.1);
    transition: transform 0.5s;
    border: none;
  }
`;
const WrapperGeneral = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-column-gap: 19px;
  grid-row-gap: 24px;
  grid-column-gap: 27px;
  grid-row-gap: 72px;
  width: 81%;
  margin: 0 auto;
  @media (max-width: 1400px) {
    display: grid;
    width: 90%;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 19px;
    grid-row-gap: 24px;
  }
  @media (max-width: 1140px) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 19px;
    grid-row-gap: 24px;
  }
  @media (max-width: 755px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 19px;
    grid-row-gap: 24px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  span {
    cursor: pointer;
  }
  &:hover {
    .button {
      visibility: visible;
      opacity: 1;
    }
    .name {
      color: ${color.primary.blue};
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const Name = styled(Text)`
  padding: 24px 0 8px;
  @media (max-width: 650px) {
    padding: 14px 0 8px;
    font-size: 30px !important;
  }
`;
const Position = styled(Text)``;
const Image = styled.img`
  width: fit-content;
  height: 100%;
  /* @media (max-width: 1390px) {
    height: fit-content;
    width: 100%;
  } */
`;
const BoxImage = styled.div`
  position: relative;
  height: 223.46px;
  width: 223.46px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  @media (max-width: 1390px) {
    height: fit-content;
    width: 100%;
  }
  @media (max-width: 376px) {
    height: 158px;
    width: 158px;
  }
`;
