import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography, spacing } from "../../shared/style";
import left from "../../images/arrow-left.svg";
import right from "../../images/arrow-right.svg";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import doubleQuote from "../../images/doubleQuote.svg";
import { Button } from "../../components/Button/Button";
import { navigate } from "gatsby";
import { CarouselSlick } from "../../components/CarouselSlick/CarouselSlick";

export const Dream = ({ data }) => {
  const { primary, items } = data || {};
  const [item, setItem] = useState(items[0]);
  const [index, setIndex] = useState(0);
  const changeItem = (i) => {
    setIndex(i);
    setItem(items[i]);
  };
  const redirectPage = (url) => {
    navigate(`${url}`);
  };
   
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    dots: true,
  };
  if (!primary || !items) return null;
  return (
    <WrapperSection>
      <WrapperGeneral>
        <WrapperTitle>
          <Title
            font={typography.type.code}
            tag="h3"
            lineHeight={90}
            weight={typography.weight.regular}
            color={color.primary.blue}
            capital="uppercase"
          >
            <RichText
              render={primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
          <WrapperSlide>
            <GroupButton>
              <ButtonSlide
                onClick={() =>
                  changeItem(index > 0 ? index - 1 : items.length - 1)
                }
                icon={left}
              ></ButtonSlide>
              <ButtonSlide
                onClick={() =>
                  changeItem(index < items.length - 1 ? index + 1 : 0)
                }
                icon={right}
              ></ButtonSlide>
            </GroupButton>
            <WrapperImage>
              <WrapperItem>
                <Item>
                  <Info>
                    <BoxLeft>
                      <BoxAvatar>
                        <Avatar
                          src={item?.avatar?.url}
                          alt={item?.avatar?.alt}
                        />
                      </BoxAvatar>
                      <Detail>
                        <Icon src={doubleQuote} />
                        <Name
                          font="Oxygen"
                          size={20}
                          lineHeight={28}
                          weight={typography.weight.bold}
                          color={color.netraul.black100}
                        >
                          {item?.name?.text}
                        </Name>
                        <Position
                          font="Oxygen"
                          weight={typography.weight.regular}
                          size={14}
                          lineHeight={22}
                          color={color.netraul.black100}
                        >
                          {item?.posittion?.text}
                        </Position>
                      </Detail>
                    </BoxLeft>
                    <Image
                      src={item?.partner?.document?.data?.logo?.url}
                      alt={item?.partner?.document?.data?.logo?.alt}
                    />
                  </Info>
                  <Comment
                    font="Oxygen"
                    lineHeight={26}
                    color={color.netraul.black90}
                  >
                    <RichText
                      render={item?.comment?.raw}
                      htmlSerializer={htmlSerializer}
                    />
                  </Comment>
                </Item>
              </WrapperItem>
            </WrapperImage>
          </WrapperSlide>
        </WrapperTitle>
        <WrapperContentDesktop>
          <Description
            font={typography.type.code}
            size={typography.size.l3}
            lineHeight={56}
            weight={typography.weight.regular1}
            color={color.primary.white}
            capital="uppercase"
          >
            {primary?.course_title?.text}
          </Description>
          <Deadline>
            <Content
              font={typography.type.primary}
              size={typography.size.s3}
              lineHeight={26}
              weight={typography.weight.regular}
              color={color.primary.white}
            >
              Deadline:{" "}
            </Content>
            <Content
              font={typography.type.primary}
              size={typography.size.s3}
              lineHeight={26}
              weight={typography.weight.bold}
              color={color.primary.white}
            >
              {primary.course_deadline}
            </Content>
          </Deadline>
          <Content
            font={typography.type.primary}
            size={typography.size.s3}
            lineHeight={26}
            weight={typography.weight.regular}
            color={color.primary.white}
          >
            <RichText
              render={primary?.course_description?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Content>
          <Offer>
            <Content
              font={typography.type.code}
              size={typography.size.l3}
              lineHeight={56}
              weight={typography.weight.regular1}
              color={color.primary.yellow}
            >
              {primary?.currency_suffix?.text}
              {Number(primary?.course_price?.text.toString())-Number(primary?.course_discount?.text)}
            </Content>
            <BorderCost>
              <ContentCost
                font={typography.type.code}
                size={typography.size.l1}
                lineHeight={36}
                weight={typography.weight.regular}
                color={color.primary.white}
              >
                {primary?.currency_prefix?.text}
                {primary?.course_price?.text}
              </ContentCost>
            </BorderCost>
            {primary.course_price ? (
              <Content
                font={typography.type.code}
                size={typography.size.l1}
                lineHeight={36}
                capital="uppercase"
                weight={typography.weight.regular1}
                color={color.primary.white}
              >
                early bird offer
              </Content>
            ) : null}
          </Offer>
          <Statistic>
            <ContentBoxRight>
              <Content
                class="benefit"
                font={typography.type.primary}
                size={typography.size.s3}
                lineHeight={26}
                weight={typography.weight.regular}
                color={color.primary.white}
              >
                <RichText
                  render={primary?.offer_description?.raw}
                  htmlSerializer={htmlSerializer}
                />
                {/* <RichText
                  render={change?.description?.raw}
                  htmlSerializer={htmlSerializer}
                /> */}
              </Content>
              <ExtraCost>
                <Content
                  font={typography.type.code}
                  size={typography.size.l1}
                  lineHeight={36}
                  weight={typography.weight.regular}
                  color={color.primary.yellow}
                >
                  +{primary?.currency_suffix?.text}
                  {primary?.offer?.text}
                  {/* <RichText
                    render={secondary?.title?.raw}
                    htmlSerializer={htmlSerializer}
                  /> */}
                </Content>
              </ExtraCost>
            </ContentBoxRight>
          </Statistic>
          <Footer>
            <ButtonExtended
              type="primary"
              onClick={() => redirectPage("/checkout")}
              size={16}
              lineHeight={26}
            >
              Apply now
            </ButtonExtended>
          </Footer>
        </WrapperContentDesktop>
      </WrapperGeneral>
      <Mobile>
        <CarouselSlick settings={settings}>
          {items?.map((item, i) => (
            <WrapperMobile key={i}>
              <ContentMobile>
                <WrapperMobileItem>
                  <ItemMobile>
                    <InfoMobile>
                      <BoxAvatar>
                        <AvatarMobile
                          src={item?.avatar?.url}
                          alt={item?.avatar?.alt}
                        />
                      </BoxAvatar>
                      <DetailMobile>
                        <IconMobile src={doubleQuote} />
                        <NameMobile
                          font="Oxygen"
                          size={20}
                          lineHeight={28}
                          weight={typography.weight.bold}
                          color={color.netraul.black100}
                        >
                          <RichText
                            render={item?.name?.raw}
                            htmlSerializer={htmlSerializer}
                          />
                        </NameMobile>
                        <PositionMobile
                          font="Oxygen"
                          weight={typography.weight.regular}
                          size={14}
                          lineHeight={22}
                          color={color.netraul.black100}
                        >
                          <RichText
                            render={item?.posittion?.raw}
                            htmlSerializer={htmlSerializer}
                          />
                        </PositionMobile>
                      </DetailMobile>
                    </InfoMobile>
                    <Comment
                      font="Oxygen"
                      lineHeight={26}
                      color={color.netraul.black90}
                    >
                      <RichText
                        render={item?.comment?.raw}
                        htmlSerializer={htmlSerializer}
                      />
                    </Comment>
                  </ItemMobile>
                </WrapperMobileItem>
              </ContentMobile>
            </WrapperMobile>
          ))}
        </CarouselSlick>
      </Mobile>
    </WrapperSection>
  );
};
const PositionMobile = styled(Text)`
  height: 22px;
  span {
    font-weight: ${typography.weight.regular};
    font-size: 14px !important;
    line-height: 22px !important;
  }
`;
const DetailMobile = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;
const Icon = styled.img`
  width: 25px;
`;
const Deadline = styled.div`
  margin: 12px 0 28px;
  span:first-child {
    opacity: 0.5;
  }
  @media (max-width: 650px) {
    margin-bottom: 24px;
  }
`;
const Statistic = styled.div``;
const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  box-sizing: border-box;
  background-color: ${color.primary.blue10};
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 0;
  }
  @media (max-width: 650px) {
    gap: 0;
  }
`;
const ContentBoxRight = styled.div``;

const IconMobile = styled.img`
  width: 25px;
  position: absolute;
  top: 32px;
`;
const ItemMobile = styled.div`
  position: relative;
  padding: 0 30px;
  @media (max-width: 992px) {
    padding: 0 50px 50px 50px;
  }
  @media (max-width: 650px) {
    padding: 0 20px 40px;
  }
`;
const AvatarMobile = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const WrapperMobile = styled.div`
  margin: 0 auto;
  box-sizing: border-box;
`;
const Mobile = styled.div`
  display: none;
  .slick-dots{
    margin-top: 23px;
    bottom: auto;
  }
  .slick-dots li button:before
    {
        font-size: 12px;
        line-height: 12px;
    }
  @media (max-width: 992px) {
    width: 91%;
    margin: auto;
    display: block;
    padding-bottom: 80px;
  }
`;
const ContentMobile = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
`;
const WrapperMobileItem = styled.div`
  background: linear-gradient(to bottom, #e9ecf1 43px, #fff 30px);
  width: auto;
`;
const InfoMobile = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;
`;
const NameMobile = styled(Text)`
  height: 28px;
`;

const WrapperGeneral = styled.div`
  padding: 150px 130px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 32px;
  @media (max-width: 1400px) {
    padding: 100px 0;
    gap: 20;
    width: 91%;
    margin: 0 auto;
  }
  @media (max-width: 992px) {
    flex-direction: column;
    padding-bottom: 60px;
  }
`;
const ButtonExtended = styled(Button)`
  background: ${color.primary.yellow};
  color: ${color.netraul.black100};
  border-radius: ${spacing.borderRadius.Larger}px;
  height: 65px;
  white-space: nowrap;
  padding: 20px 45px;
  @media (max-width: 650px) {
  }
`;
const Footer = styled.div`
  margin-top: 60px;
  @media (max-width: 650px) {
    margin-top: 30px;
  }
`;
const ExtraCost = styled.span`
  width: 100px;
  position: relative;
  span {
    position: absolute;
    top: 0px;
    /* left: 10px; */
    word-break: normal;
  }
`;
const WrapperTitle = styled.div`
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;
  box-sizing: border-box;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
    width: 100%;
  }
`;
const WrapperContent = styled.div`
  width: 52%;
  display: flex;
  flex-direction: column;
  background-color: ${color.primary.blue};
  padding: 50px 50px 64px;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    padding: 40px 20px;
  }
  @media (max-width: 992px) {
    width: 100%;
    padding: 50px 50px 64px;
  }
  @media (max-width: 650px) {
    padding: 40px 20px;
  }
`;
const WrapperContentDesktop = styled(WrapperContent)``;
const Offer = styled.div`
  padding-top: 24px;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  span {
    letter-spacing: -0.015em;
  }
  .benefit {
    @media (max-width: 650px) {
      font-size: 62px !important;
      font-weight: ${typography.weight.regular1};
      line-height: 62px !important;
    }
  }
  @media (max-width: 650px) {
    padding: 30px 0;
  }
`;
const Title = styled(Text)`
  letter-spacing: -0.015em;
  @media (max-width: 1200px) {
    font-size: 62px !important;
    font-weight: ${typography.weight.regular1};
    line-height: 62px !important;
  }
  @media (max-width: 992px) {
    padding-bottom: 0px;
  }
  @media (max-width: 650px) {
  }
`;
const WrapperSlide = styled.div`
  padding-top: 72px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 992px) {
    display: none;
  }
`;
const GroupButton = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 5px;
`;
const ButtonSlide = styled(Button)`
  padding: 13px 15px;
  border-radius: 0;
  background-color: ${color.primary.white};
  border: 1px solid #ffffff;
  span {
    display: none;
  }
`;
const Image = styled.img`
  height: fit-content;
  max-width: 90px;
`;
const WrapperImage = styled.div`
  background-color: ${color.primary.white};
  padding: 30px;
  @media (max-width: 1250px) and (min-width: 1001px) {
    padding: 35px 50px;
  }
  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
  }
`;
const Description = styled(Text)`
  letter-spacing: -0.015em;
  span {
    font-size: 56px !important;
    line-height: 56px !important;
    font-weight: 500;
  }
  @media (max-width: 650px) {
    font-size: 36px !important;
    line-height: 36px;
  }
`;
const Content = styled(Text)``;
const BorderCost = styled.span`
  position: relative;
  ::before {
    transform: rotate(68deg);
    position: absolute;
    height: 64px;
    top: -26px;
    right: 40px;
    width: 1px;
    content: " ";
    background-color: #ff3737;
  }
`;
const ContentCost = styled(Text)`
  opacity: 0.3;
  padding: 0 17px 0 15px;
`;

const WrapperItem = styled.div`
  background: ${color.primary.white};
  @media (max-width: 1000px) {
    width: auto;
    margin-bottom: 30px !important;
  }
  @media (max-width: 650px) {
    &:last-child {
    }
  }
`;

const Item = styled.div`
  padding: 0 30px;
  @media (max-width: 1100px) and (min-width: 1000px) {
    padding: 0 10px;
  }
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;
const BoxLeft = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;
const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const BoxAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100px;
  height: 100px;
  @media (max-width: 992px) {
    width: 102px;
    height: 136px;
  }
`;
const Detail = styled.div`
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    padding-left: 20px;
  }
`;
const Name = styled(Text)`
  padding-bottom: 4px;
  padding-top: 19px;
`;
const Position = styled(Text)`
  opacity: 0.67;
`;
const Comment = styled(Text)``;
