import { useStaticQuery, graphql } from "gatsby"

export const useQueryFundamentals = () => {
  const { allPrismicFundamentals } = useStaticQuery(
    graphql`
      query QueryFundamental {
        allPrismicFundamentals {
          nodes {
            data {
              banner_logo {
                url
                alt
              }
              banner_title {
                html
                text
                raw
              }
              banner_description {
                html
                text
                raw
              }
              banner_button_label {
                html
                text
                raw
              }
              banner_button_link {
                url
                type
                link_type
              }
              video {
                html
                text
                raw
              }
              body {
                ... on PrismicFundamentalsBodyAbout {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                    start_date(formatString: "DD MMMM YYYY")
                    tuition_fee {
                      html
                      text
                      raw
                    }
                    tuition_fee_prefix {
                      text
                    }
                    timeline {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body1 {
                ... on PrismicFundamentalsBody1Why {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    icon {
                      url
                      alt
                    }
                    reason_title {
                      html
                      text
                      raw
                    }
                    reason_description {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body2 {
                ... on PrismicFundamentalsBody2WhatWillLearn {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    icon_lesson {
                      url
                      alt
                    }
                    lesson_description {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body3 {
                ... on PrismicFundamentalsBody3MentorsAndSpeakers {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    button_label {
                      html
                      text
                      raw
                    }
                    button_link {
                      url
                      type
                      link_type
                    }
                  }
                  items {
                    member {
                      document {
                        ... on PrismicTeamMembers {
                          data {
                            name {
                              html
                              text
                              raw
                            }
                            avatar {
                              url
                              alt
                            }
                            position {
                              html
                              text
                              raw
                            }
                            contacts {
                              contact_label
                              contact_link {
                                url
                                type
                                link_type
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              body4 {
                ... on PrismicFundamentalsBody4MakeDreamStartupComeLife {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    course_title {
                      html
                      text
                      raw
                    }
                    course_description {
                      html
                      text
                      raw
                    }
                    course_price {
                      html
                      text
                      raw
                    }
                    course_deadline(formatString: "DD MMM YYYY")
                    course_discount {
                      html
                      text
                      raw
                    }
                    currency_prefix {
                      text
                    }
                    currency_suffix {
                      text
                    }
                    offer {
                      html
                      text
                      raw
                    }
                    offer_description {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    avatar {
                      url
                      alt
                    }
                    name {
                      html
                      text
                      raw
                    }
                    posittion {
                      html
                      text
                      raw
                    }
                    partner {
                      document {
                        ... on PrismicPartners {
                          data {
                            logo {
                              url
                              alt
                            }
                          }
                        }
                      }
                    }
                    comment {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body5 {
                ... on PrismicFundamentalsBody5Faqs {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    question {
                      html
                      text
                      raw
                    }
                    answer {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body7 {
                ... on PrismicFundamentalsBody7Program {
                  items {
                    name {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    price {
                      text
                    }
                  }
                }
              }
              body6 {
                ... on PrismicFundamentalsBody6GeneralCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    robot {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                    googlebot {
                      html
                      text
                      raw
                    }
                    favicon {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                    bingbot {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicFundamentalsBody6OpenGraph {
                  primary {
                    og_url {
                      url
                      type
                      link_type
                    }
                    og_type {
                      html
                      text
                      raw
                    }
                    og_title {
                      html
                      text
                      raw
                    }
                    og_site_name {
                      html
                      text
                      raw
                    }
                    og_locale {
                      html
                      text
                      raw
                    }
                    og_image_width {
                      html
                      text
                      raw
                    }
                    og_image_height {
                      html
                      text
                      raw
                    }
                    og_image {
                      alt
                      url
                    }
                    og_description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicFundamentalsBody6TwitterCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    image {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicFundamentalsBody6PinterestProductPin {
                  slice_type
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    price
                    currency
                    availability
                  }
                }
              }
            }
          }
        }
      }
      `
  )

  return {
    allPrismicFundamentals
  }
}