import React from "react";
import styled from "styled-components";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import { color, typography, spacing } from "../../shared/style";
import { Text } from "../../components/Text/Text";
import { Button } from "../../components/Button/Button";
import { navigate } from "gatsby";

export const Banner = ({
  button,
  button_link,
  title,
  description,
  video,
  logo,
  seo,
  programs
}) => {
  const redirectPage = (url) => {
    navigate(`/${url}`);
    typeof sessionStorage !== "undefined" &&
      sessionStorage.setItem(
        "dataCheckout",
        JSON.stringify({ title, programs, seo })
      );
  };
  return (
    <WrapperSection>
      <WrapperGeneral>
        <Image src={logo?.url} />
        <Content>
          <Title
            font={typography.type.code}
            tag="h2"
            color={color.primary.white}
            capital="uppercase"
            size={typography.size.extracode}
            weight={typography.weight.regular1}
            lineHeight={120}
            align="left"
          >
            <RichText render={title?.raw} htmlSerializer={htmlSerializer} />
          </Title>
          <BoxRight>
            <ContentRight>
              <Description
                font={typography.type.primary}
                color={color.primary.white}
                size={typography.size.s3}
                weight={typography.weight.bold}
                lineHeight={26}
                align="left"
              >
                {description?.text}
                {/* <RichText
                render={description?.raw}
                htmlSerializer={htmlSerializer}
              /> */}
              </Description>
              <ButtonExtended
                type="primary"
                onClick={() => redirectPage(button_link?.type || "checkout")}
                size={16}
                lineHeight={26}
              >
                {button}
              </ButtonExtended>
            </ContentRight>
          </BoxRight>
        </Content>
        <BottomContent>
          <Video>
            <RichText render={video?.raw} htmlSerializer={htmlSerializer} />
          </Video>
        </BottomContent>
      </WrapperGeneral>
    </WrapperSection>
  );
};
const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  box-sizing: border-box;
  height: auto;
  background: ${color.secondary.danube};
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ContentRight = styled.div`
  padding-left: 16%;
  width: 65%;
  @media (max-width: 1400px) {
    width: 75%;
  }
  @media (max-width: 1275px) {
    width: 85%;
    padding-left: 8%;
  }
  @media (max-width: 1125px) {
    width: 95%;
  }
  @media (max-width: 650px) {
    width: 100%;
    padding-left: 0;
    display: flex;
    flex-direction: column;
  }
`;
const Image = styled.img`
  padding-top: 150px;
  padding-bottom: 11px;
  @media (max-width: 650px) {
    padding-left: 25%;
    display: none;
  }
`;
const Video = styled.div`
  div {
    iframe {
      height: 600px;
      @media (max-width: 1000px) and (min-width: 651px) {
        height: 400px;
      }
      @media (max-width: 650px) {
        height: 200px;
      }
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: -25%;
  }
  @media (max-width: 650px) {
    margin-bottom: -30%;
  }
`;
const WrapperGeneral = styled.div`
  width: 81%;
  @media (max-width: 1400px) {
    width: 91%;
  }
  @media (max-width: 650px) {
    margin-top: 80px;
    padding: 70px 20px 0;
  }
`;
const ButtonExtended = styled(Button)`
  border-radius: ${spacing.borderRadius.Larger}px;
  height: 65px;
  white-space: nowrap;
  font-size: ${typography.size.s3}px;
  line-height: 26px;
  font-weight: ${typography.weight.bold};
  margin-top: 47px;
  padding: 20px 45px;
  text-transform: capitalize;
  span {
    overflow: unset;
  }
  @media (max-width: 650px) {
    margin: 30px auto 69px;
  }
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 65px;
  @media (max-width: 1400px) {
    align-items: unset;
  }
  @media (max-width: 650px) {
    padding: 0 0px 0;
    flex-direction: column;
  }
`;
const BottomContent = styled.div`
  height: auto;
  margin: 0 auto -25.926%;
  @media (max-width: 1225px) {
    margin: 0 auto -30.926%;
  }
  @media (max-width: 1000px) {
    margin: 0 auto;
  }
  @media (max-width: 650px) {
    margin: 0 auto 13.074%;
  }
  @media (max-width: 565px) {
    margin: 0 auto 4.074%;
  }
  @media (max-width: 376px) {
    margin: 0 auto;
  }
`;
const Title = styled(Text)`
  text-align: left;
  width: 50%;
  letter-spacing: -0.015em;
  @media (max-width: 1440px) {
    font-size: ${typography.size.code}px !important;
    line-height: 90px !important;
    text-align: center;
    text-align: left;
  }
  @media (max-width: 1080px) {
    font-size: ${typography.size.l4}px !important;
    line-height: 62px !important;
  }
  @media (max-width: 650px) {
    white-space: normal;
    width: 100%;
    text-align: center;
  }
`;
const Description = styled(Text)`
  display: block;
  @media (max-width: 650px) {
    text-align: center;
    white-space: normal;
    margin-top: 16px;
  }
`;
const BoxRight = styled.div`
  width: 50%;
  @media (max-width: 650px) {
    width: 100%;
  }
`;
