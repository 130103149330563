import React from "react";
import styled from "styled-components";
import { color } from "../../shared/style";
import { Banner } from "./Banner";
import { Program } from "./Program";
import { Learn } from "./Learn";
import { Speaker } from "./Speaker";
import { Dream } from "./Dream";
// import { Question } from "./Question";
import AboutThisProgram from "./AboutThisProgram";
import { Question } from "./Question";

export const FundamentalContainer = ({ data, seo }) => {
  const programs = data?.body7[0]?.items;
  return (
    <WrapperLayout>
      <Banner
        button={data?.banner_button_label?.text}
        button_link={data?.banner_button_link?.text}
        title={data?.banner_title}
        description={data?.banner_description}
        video={data?.video}
        logo={data?.banner_logo}
        seo={seo}
        programs={programs}
      />
      <AboutThisProgram data={data?.body[0]} />
      <Program data={data?.body1[0] === undefined ? {} : data?.body1[0]} />
      <Learn data={data?.body2[0] === undefined ? {} : data?.body2[0]} />
      <Speaker data={data?.body3[0] === undefined ? {} : data?.body3[0]} />
      {data?.body4[0] && (
        <Dream data={data?.body4[0] === undefined ? {} : data?.body4[0]} />
      )}
      {data?.body5[0] && (
        <Question data={data?.body5[0] === undefined ? {} : data?.body5[0]} />
      )}
    </WrapperLayout>
  );
};

const WrapperLayout = styled.div`
  margin-bottom: 100px;
  overflow: hidden;
  margin: 0 auto;
  background-color: ${color.primary.white};
  position: relative;
`;
