import { RichText } from "prismic-reactjs";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { Text } from "../../components/Text/Text";
import { color, spacing, typography } from "../../shared/style";
import useWindowSize from "./useWindowSize";

export const Learn = ({ data }) => {
  const [width] = useWindowSize();
  const { items, primary } = data || {};
  // const [seeMore, setSeemore] = useState(false);
  const [listItem, setListItem] = useState([]);
  // const redirectPage = (url) => {
  //   navigate(`${url}`);
  // };

  useEffect(() => {
    let listTamp = [...items];
    if (width <= 650) {
      setListItem(listTamp.splice(0, 4));
    } else {
      setListItem(listTamp);
    }
  }, [width, items]);

  const handleListMore = () => {
    let listTamp = [...items];
    listItem.length !== items.length
      ? setListItem(listTamp)
      : setListItem(listTamp.splice(0, 4));
  };
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <Title
        font={typography.type.code}
        tag="h3"
        weight={typography.weight.regular1}
        lineHeight={90}
        align="center"
        color={color.primary.blue}
        capital="uppercase"
      >
        <RichText
          render={primary?.title?.raw}
          htmlSerializer={htmlSerializer}
        />
      </Title>
      <Description
        font={typography.type.primary}
        color={color.netraul.black100}
        size={typography.size.s3}
        weight={typography.weight.primary}
        lineHeight={26}
        align="center"
      >
        <RichText
          render={primary?.description?.raw}
          htmlSerializer={htmlSerializer}
        />
      </Description>
      <WrapperContent>
        <WrapperGeneral>
          <List>
            {listItem &&
              listItem?.map((item, i) => {
                return (
                  <ListItem key={i}>
                    <HeaderItem>
                      <Icon src={item?.icon_lesson?.url} />
                    </HeaderItem>
                    <BoxContent>
                      <TitleItem
                        size={typography.size.s2}
                        weight={typography.weight.regular}
                        lineHeight={22}
                        capital="uppercase"
                        font={typography.type.primary}
                        color={color.netraul.black70}
                      >
                        {i < 9 ? (
                          <>lesson {`0${i + 1}`}</>
                        ) : (
                          <>lesson {`${i + 1}`}</>
                        )}
                      </TitleItem>
                      <DescriptionItem
                        size={typography.size.m1}
                        weight={typography.weight.bold}
                        lineHeight={30}
                        font={typography.type.primary}
                        color={color.primary.blue}
                      >
                        {item?.lesson_description?.text}
                      </DescriptionItem>
                    </BoxContent>
                  </ListItem>
                );
              })}
          </List>
          <Footer>
            {items.length > 4 && (
              <GroupButton>
                <ButtonExtended onClick={handleListMore} type="primary">
                  {listItem.length !== items.length ? "See more" : "See less"}
                </ButtonExtended>
              </GroupButton>
            )}
          </Footer>
        </WrapperGeneral>
      </WrapperContent>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  background-color: ${color.primary.blue05};
  max-width: 1500px;
  margin: auto;
  box-sizing: border-box;
`;
const ButtonExtended = styled(Button)`
  border-radius: ${spacing.borderRadius.Larger}px;
  height: 65px;
  white-space: nowrap;
  padding: 20px 45px;
  text-transform: capitalize;
  display: none;
  @media (max-width: 650px) {
    margin: 40px auto 0px;
    display: block;
  }
`;
const GroupButton = styled.div`
  text-align: center;
`;
const Footer = styled.div``;
const Title = styled(Text)`
  letter-spacing: -0.015em;
  padding: 130px 0 32px;
  display: block;
  max-width: 91%;
  margin: 0 auto;
  @media (max-width: 1400px) {
    padding: 80px 0 5px;
    width: 91%;
    margin: 0 auto;
  }
  @media (max-width: 650px) {
    padding: 80px 0 24px;
    text-align: left;
    margin: 0 auto;
    font-weight: ${typography.weight.regular1};
    font-size: ${typography.size.l4}px !important;
    line-height: 62px !important;
  }
`;
const Description = styled(Text)`
  width: 42%;
  margin: 0 auto;
  padding-bottom: 60px;
  display: block;
  @media (max-width: 1400px) {
    width: 78%;
  }
  @media (max-width: 650px) {
    width: 91%;
    text-align: left;
    padding-bottom: 40px;
  }
`;
const WrapperContent = styled.div`
  width: 81%;
  margin: 0 auto;
  padding-bottom: 150px;
  @media (max-width: 1400px) {
    width: 91%;
  }
  @media (max-width: 650px) {
    padding-bottom: 80px;
  }
`;
const WrapperGeneral = styled.div``;
const DescriptionItem = styled(Text)``;
const TitleItem = styled(Text)`
  display: block;
  letter-spacing: 0.2em;
  padding-bottom: 16px;
`;
const BoxContent = styled.div`
  padding: 0 32px 37px;
  @media (max-width: 650px) {
    padding: 0 20px 20px;
  }
`;
const List = styled.div`
  display: grid;
  grid-gap: 26px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  @media (max-width: 1250px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px;
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
  }
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 10px;
  }
`;
const ListItem = styled.div`
  background-color: ${color.primary.white};
  min-height: 184px;
  cursor: pointer;
  span {
    cursor: pointer;
  }
  :hover {
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    div {
      span:last-child {
        color: ${color.secondary.danube};
      }
    }
  }
`;
const Icon = styled.img`
  max-width: 32px;
  max-height: 32px;
  width: 100%;
  height: 100%;
`;
const HeaderItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 32px 30px 32px;
  @media (max-width: 650px) {
    padding: 20px 20px 14px 20px;
  }
`;
